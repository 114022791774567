/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  ZoomControl,
} from "react-leaflet";
import L from "leaflet";
import { LocationIcon } from "../../assets/icons";
import ReactDOMServer from "react-dom/server";
import { getFormatedDateTime } from "../../pages/map";

const Map = ({ locations }) => {
  const [mapCenter, setMapCenter] = useState([0, 0]);

  const mapMarker = (color) => {
    return L.divIcon({
      html: ReactDOMServer.renderToString(
        <LocationIcon color={color} height={34} width={34} />
      ),
      iconSize: [34, 34],
      className: "custom-marker",
    });
  };

  const CustomMap = () => {
    const map = useMap();

    useEffect(() => {
      if (
        locations.currentLocation[0]?.Latitude &&
        locations.currentLocation[0]?.Longitude
      ) {
        setMapCenter([
          locations.currentLocation[0].Latitude,
          locations.currentLocation[0].Longitude,
        ]);

        map.flyTo(
          [
            locations.currentLocation[0].Latitude,
            locations.currentLocation[0].Longitude,
          ],
          10
        );
      }
    }, [locations, map]);

    return null;
  };

  return (
    <MapContainer
      center={mapCenter}
      zoom={3}
      className="w-full h-screen z-0"
      zoomControl={false}
    >
      <TileLayer url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=elOJ3hZCpng5pPPHyNW1" />
      {mapCenter[0] === 0 ? <CustomMap /> : null}
      {Object.keys(locations)?.map((entry, index) => {
        return locations[entry].map((item, keyIndex) => {
          if (item?.Latitude !== 0 && item?.Longitude !== 0) {
            return (
              <Marker
                key={`${index}-${keyIndex}`}
                position={[item?.Latitude, item?.Longitude]}
                icon={mapMarker(
                  entry === "currentLocation" ? "#FF0000" : "#5A5A5A"
                )}
                zIndexOffset={entry === "currentLocation" ? 2 : 1}
              >
                <Popup>
                  <div>
                    Time: {getFormatedDateTime(item?.Time)}
                    {item?.Sector !== undefined ? (
                      <div>Sector: {item?.Sector}</div>
                    ) : null}
                    {item?.Beamwidth && <div>Beamwidth: {item?.Beamwidth}</div>}
                  </div>
                </Popup>
              </Marker>
            );
          }
          return null;
        });
      })}
      <ZoomControl position="topright" zoom={3} />
    </MapContainer>
  );
};

export default Map;
