import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/header.css";
import LogoSvg from "../../../assets/icons/logo";

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("email");

  return (
    <div className="h-full">
      <div className="w-full flex justify-between items-center bg-[#D6E3EC] px-6 py-5">
        <LogoSvg className="h-11 w-52" />
        <div className="flex justify-center items-center gap-5">
          <div className="text-[#213660] align-middle font-extrabold mt-1.5">
            {userEmail}
          </div>
          <button
            className="relative border rounded-full py-3 text-sm  font-bold  text-[#213660]  overflow-hidden w-[120px] logout_button_bg"
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
          >
            <div className="w-full flex justify-center">
              <div className="absolute top-0 logout_glass_shade" />
            </div>
            <div className="relative">{"Log Out"}</div>
          </button>
        </div>
      </div>
      <div
        style={{
          height: "calc(100vh - 84px)",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
