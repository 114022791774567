import axios from "axios";

const instance = axios.create({
  baseURL: `https://backend.trialtrackpro.com/api/v1/`,
  // baseURL: `https://map-time-backend.vercel.app/api/v1/`,
  // baseURL: `http://localhost:3001/api/v1/`,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.response && error.response.data.statusCode === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const axiosGet = async ({ path }) => {
  const res = await instance.get(path);
  return res;
};

export const axiosPost = async ({ path, payload }) => {
  const res = await instance.post(path, payload);
  return res;
};

export const axiosPatch = async ({ path, payload }) => {
  const res = await instance.patch(path, payload);
  return res;
};

export const axiosDelete = async ({ path }) => {
  const res = await instance.delete(path);
  return res;
};
