import * as Yup from "yup";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

export const ValidationSchema = {
  NAME: Yup.string().required().label("Name"),
  EMAIL: Yup.string()
    .required()
    .matches(emailRegex, "Invalid Email Address")
    .label("Email"),
  PASSWORD: Yup.string()
    .required()
    .min(6)
    .matches(
      passwordRegex,
      "Require atleast 1 uppercase and 1 lowercase letter"
    )
    .label("Password"),
  CONFIRM_PASSWORD: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match")
    .label("Confirm Password"),
};
