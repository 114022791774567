import React, { useState } from "react";
import { Formik } from "formik";
import AuthLayout from "../../components/layout/authLayout";
import "../../styles/learnMore.css";
import desktopBG from "../../assets/images/LearnMore.png";
import mapImage from "../../assets/images/mapImage.png";
import timeDate from "../../assets/images/timeDate.png";
import { EmailIcon } from "../../assets/icons";
import { LearnMoreValidationScheme } from "../../utils/LearnMoreValidation";
import { addWaitListEmail } from "../../api/learnMore";

const LearnMore = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailAdded, setEmailAdded] = useState("");

  const onSubmitHandler = async (values) => {
    setIsLoading(true);
    setErrorMessage("");
    setEmailAdded("");
    try {
      const response = await addWaitListEmail(values);
      if (response) {
        setEmailAdded(response?.message);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Login failed");
    }
    setIsLoading(false);
  };

  return (
    // Learn More Page Testing commit
    <AuthLayout isSignUp={false}>
      <div className="flex flex-col pb-20">
        <div className="relative flex flex-col justify-center items-center gap-7 ">
          <img
            src={desktopBG}
            className="h-full w-full absolute inset-0 -z-10 top-0 brightness-110" //h-full w-full absolute inset-0 -z-10  top-0
            //h-full w-full absolute inset-0 -z-10 object-cover lg:object-fill pb-16 sm:pb-48 lg:pb-80
          />
          <h1 className="font-serif text-lg sm:text-3xl text-center text-white pt-20 lg:pt-48 mt-7 px-5 sm:px-10 lg:px-0">
            The Last Word in Simplicity.
          </h1>
          <p className="font-serif text-base sm:text-xl max-w-6xl px-5 sm:px-10 lg:px-5">
            TrialTrack Professional is a powerful platform for visualizing cell
            phone records in criminal cases, providing historical dimension to
            otherwise cryptic data.
          </p>
          <img
            src={mapImage}
            className="h-full w-full max-w-6xl rounded-2xl -mb-48 px-5 sm:px-10 lg:px-0 mx-5 object-fill "
          />
        </div>
        <div className="font-serif text-base sm:text-xl px-5 sm:px-10 lg:px-5 mt-48 pt-7 flex flex-col justify-center items-center max-w-6xl gap-7 mx-auto">
          <p>
            Meticulously designed to be the last word in accuracy and
            simplicity, TrialTrack Professional allows for analysis of tens of
            thousands of rows of overwhelming and arcane data -- analyzed and
            checked for accuracy with a simple timeline slider, enabled by
            default.
          </p>
          <img src={timeDate} className=" h-full w-full max-w-md rounded-xl" />
          <p className="text-center underline">Automatic everything</p>
          <p>
            Custom code automatically parses and converts the records into
            easily animated maps with adjustable timezones from UTC/GMT to local
            time.
          </p>
          <p>
            Glean insights into records of all kinds. AT&T, T-Mobile, TDoA
            (Timing Advance) or standard CDR (Call Detail Records) analysis.
            Accomplish more in 5 minutes with TrialTrack than you could with
            hours or even weeks of manual analysis.
          </p>
        </div>
        <div className="w-[90%] bg-[#F5F5F5] rounded-lg shadow-lg mt-10 py-10 flex justify-center items-center sm:max-w-2xl md:max-w-4xl lg:max-w-6xl mx-auto gap-6 flex-col">
          {errorMessage && (
            <div
              className={`transition-all duration-500 ease-in-out transform translate-y-0 opacity-100 text-white text-base font-bold text-center px-4 py-2 bg-red-400 border border-red-700 rounded-lg w-[90%] sm:w-[50%] h-auto self-center`}
            >
              {errorMessage}
            </div>
          )}
          {emailAdded && (
            <div className="text-white text-base font-bold text-center px-4 py-2 bg-green-400 border border-green-700 rounded-lg w-[90%] sm:w-[50%] h-auto self-center">
              {emailAdded}
            </div>
          )}
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={onSubmitHandler}
            validationSchema={LearnMoreValidationScheme}
          >
            {({ handleChange, handleSubmit, touched, errors }) => (
              <div className="flex flex-col gap-8 w-full px-4 sm:px-10 sm:w-[60%]">
                <div className="flex flex-col gap-4">
                  <div className="relative border border-[#E7E8EC] w-full py-3 px-4 gap-3 rounded-lg flex">
                    <img src={EmailIcon} alt="email-icon" />
                    <div className="flex flex-col">
                      <label
                        className="text-[#6E6D7A] text-md"
                        htmlFor={"email"}
                      >
                        {"Email"}
                      </label>

                      <input
                        type="text"
                        id={"email"}
                        placeholder={"Somebody@email.com"}
                        onChange={handleChange("email")}
                        className="focus:outline-none  text-[#6E6D7A] bg-[#F5F5F5] w-full"
                      />
                    </div>
                  </div>
                  {touched.email && errors.email && (
                    <div className="text-red-700 text-sm -mt-3">
                      {errors.email}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="w-full relative border rounded-full py-3 font-bold text-xl text-[#213660] border-[#BAE3F6] sign_in_button overflow-hidden"
                  disabled={isLoading}
                >
                  <div className="w-full flex justify-center">
                    <div className="absolute top-0 glass_shade" />
                  </div>
                  <div className="relative">
                    {isLoading ? (
                      <div className="w-full flex justify-center items-center">
                        <div className="animate-spin">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4335 4335"
                            width="26"
                            height="26"
                          >
                            <path
                              fill="#8B5CF6"
                              d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      "Join Waitlist"
                    )}
                  </div>
                </button>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LearnMore;
