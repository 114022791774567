import React, { useState, useEffect } from "react";
import { formatInTimeZone } from "date-fns-tz";
import tzlookup from "tz-lookup";

const ConvertDate = ({
  fileDateTime,
  fileLatitude,
  fileLongitude,
  isExcelFile,
}) => {
  const [inputDateTime, setInputDateTime] = useState(fileDateTime);
  const [latitude, setLatitude] = useState(fileLatitude);
  const [longitude, setLongitude] = useState(fileLongitude);
  const [inputTimezone, setInputTimezone] = useState("");
  const [userTimezone, setUserTimezone] = useState("");
  const [convertedTime, setConvertedTime] = useState("");

  // Detect user's timezone
  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(detectedTimezone);
  }, []);

  // Lookup timezone based on latitude and longitude
  useEffect(() => {
    if (latitude && longitude) {
      try {
        const timezone = tzlookup(parseFloat(latitude), parseFloat(longitude));
        setInputTimezone(timezone);
      } catch (error) {
        setInputTimezone("Invalid coordinates");
      }
    }
  }, [latitude, longitude]);

  // Convert input date/time to user's timezone
  useEffect(() => {
    if (inputDateTime && inputTimezone && userTimezone) {
      try {
        // const parsedDate = new Date(`${inputDateTime} UTC`);
        const parsedDate = isExcelFile
          ? new Date(inputDateTime)
          : new Date(`${inputDateTime} UTC`);
        const fromatedString =
          inputDateTime.includes(":") && inputDateTime?.split(":")?.length === 3
            ? "MM/dd/yyyy hh:mm:ss a"
            : "MM/dd/yyyy hh:mm a";
        const converted = formatInTimeZone(
          parsedDate,
          userTimezone,
          fromatedString
        );
        setConvertedTime(converted);
      } catch (error) {
        setConvertedTime("Invalid date/time format");
      }
    }
  }, [inputDateTime, inputTimezone, userTimezone]);

  // Update states when props change
  useEffect(() => {
    setInputDateTime(fileDateTime);
    setLatitude(fileLatitude);
    setLongitude(fileLongitude);
  }, [fileDateTime, fileLatitude, fileLongitude]);

  return (
    <>
      <p className="text-xs time-style text-center w-full lg:text-base leading-normal">
        Current Time:{" "}
        <span className="font-bold">{convertedTime || "00:00"}</span>
      </p>
      <p className="text-xs text-center w-full lg:text-base leading-normal">
        Current Time Zone: <span className="font-bold">{userTimezone}</span>
      </p>
    </>
  );
};

export default ConvertDate;
