import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import Papa from "papaparse";
import CustomModal from "./CustomModal";
import { addFile } from "../../api/file";
import "../../App.css";
import { fileTypes } from "../../constants/FileTypes";
import CustomLoader from "./customLoader";
import AWS from "aws-sdk";
import { handleFileUpload as awsFileUploadHandler } from "../../utils/FileExtract";

const extractData = (data, time) => {
  const splitData = data?.split(":");
  if (splitData[0]?.includes("/")) {
    const [, Longitude, Latitude, Sector, Beamwidth] = splitData;
    return {
      Longitude: parseFloat(Longitude),
      Latitude: parseFloat(Latitude),
      Sector: parseFloat(Sector),
      Time: time,
      Beamwidth: Beamwidth,
    };
  } else {
    const [, , Longitude, Latitude, Sector, Beamwidth] = splitData;
    return {
      Longitude: parseFloat(Longitude),
      Latitude: parseFloat(Latitude),
      Sector: parseFloat(Sector),
      Time: time,
      Beamwidth: Beamwidth,
    };
  }
};

const extractCoordinatesAndSector = (fileData) => {
  const extractedDataArray = fileData?.map((data) => {
    const trimmedData = data["CellLocation"]?.slice(1, -1);
    if (trimmedData?.includes(";")) {
      const splitData = trimmedData?.split(";");
      return splitData?.map((items) => extractData(items, data["Time PT"]));
    } else {
      return [extractData(trimmedData, data["Time PT"])];
    }
  });
  return extractedDataArray;
};

// const handleXlsxUpload = async (file) => {
//   if (!file) return;

//   const reader = new FileReader();
//   return new Promise((resolve, reject) => {
//     reader.onload = (e) => {
//       try {
//         const data = new Uint8Array(e.target.result);
//         const workbook = XLSX.read(data, { type: "array" });

//         const sheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[sheetName];

//         const csvData = XLSX.utils.sheet_to_csv(worksheet);
//         resolve(csvData);
//       } catch (error) {
//         reject(error);
//       }
//     };

//     reader.onerror = (error) => {
//       reject(error);
//     };

//     reader.readAsArrayBuffer(file);
//   });
// };

const handleXlsxUpload = async (file) => {
  if (!file) return;

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async (e) => {
      try {
        const arrayBuffer = e.target.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.worksheets[0]; // Get the first worksheet

        let csvData = "";
        worksheet.eachRow((row) => {
          const rowData = row.values
            .slice(1) // Remove the first element which is the row number
            .map((cell) => (cell !== undefined ? cell : ""))
            .join(",");
          csvData += `${rowData}\n`;
        });

        resolve(csvData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

const CSVUpload = ({
  onUpload,
  setIsFileEmpty,
  selectedFormat,
  setSelectedFormat,
  showMapData,
  setShowMapData,
  isApiCalled,
  setIsApiCalled,
  allCsvData,
  csvData,
  awsFiles,
  setCSVData,
}) => {
  const [fileName, setFileName] = useState("");
  const [creatFileData, setCreatFileData] = useState();
  const fileInputRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);

  const uploadFile = async (file, fileData, fileType, name) => {
    // S3 Bucket Name
    const S3_BUCKET = "aws-upload-test-1";
    // S3 Region
    const REGION = "ap-south-1";
    // email
    const email = localStorage.getItem("email");
    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIA2UC3B3PWSFFWYVG5",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const listParams = {
      Bucket: S3_BUCKET,
    };
    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: file?.name,
      Body: file,
    };

    const listData = await s3.listObjectsV2(listParams).promise();
    const existingFiles = listData.Contents.map((obj) => obj.Key);

    // Determine file name
    let fileName = file?.name;
    let baseName = fileName;
    let extension = "";
    const dotIndex = fileName?.lastIndexOf(".");
    if (dotIndex > 0) {
      baseName = fileName.substring(0, dotIndex);
      extension = fileName.substring(dotIndex);
    }

    let counter = 1;
    while (existingFiles?.includes(`${email}/${fileName}`)) {
      // console.log("indside white loop");
      fileName = `${baseName}(${counter})${extension}`;
      counter++;
    }

    const uploadParams = {
      Bucket: S3_BUCKET,
      Key: `${email}/${fileName}`,
      Body: file,
      Metadata: {
        fileType: `${fileTypes?.indexOf(fileType)}`,
      },
    };
    // Uploading file to s3

    var response = await s3
      .putObject(uploadParams)
      .on("httpUploadProgress", (evt, data) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
        console.log("Bucket data", data);
      })
      .promise();

    if (response) {
      const payload = {
        fileName: fileName,
        // data: fileData,
        fileType: fileType,
      };
      try {
        const res = await addFile(payload);
        if (res) {
          payload.data = fileData;
          payload._id = res._id;
          onUpload(payload, true);
        }
      } catch (error) {
        console.log("mongo add file error");
      }
    }
    setFileUpload(false);
  };

  // const createAndUploadFile = (fileTable, name) => {
  //   // setFileUpload(false);
  //   const jsonData = JSON.stringify(fileTable);
  //   const jsonFileName = name?.split(".")[0] + ".json";
  //   console.log("jsonFileName", jsonFileName);
  //   const file = new File([jsonData], jsonFileName, {
  //     type: "application/json",
  //   });
  //   // setCreatFileData(newFile);
  //   uploadFile(file);
  //   // console.log('creatFileData', creatFileData)
  //   // fetchObjects();
  //   // setFileUpload(false);
  // };

  const addFileApiHandler = async (fileTable, name, fileType, file) => {
    uploadFile(file, fileTable, fileType, name);
  };

  const getSingleFileDataFromAWS = async (fileName, fileType, id) => {
    setFileUpload(true);
    const S3_BUCKET = "aws-upload-test-1";
    const REGION = "ap-south-1";
    AWS.config.update({
      accessKeyId: "AKIA2UC3B3PWSFFWYVG5",
      secretAccessKey: "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",
    });
    const email = localStorage.getItem("email");
    const objectParams = {
      Bucket: S3_BUCKET,
      Key: `${email}/${fileName}`,
    };
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const ifXlsxFile = fileName?.split(".")[1] === "xlsx";
    try {
      const objectData = await s3.getObject(objectParams).promise();
      const content = ifXlsxFile
        ? objectData.Body
        : objectData.Body.toString("utf-8");
      const res = await awsFileUploadHandler(content, fileType, fileName);
      const payload = {
        data: res,
        fileType: fileType,
        fileName: fileName,
        _id: id,
      };
      onUpload(payload);
    } catch (error) {
      console.log("getSingleFileDataFromAWS", error);
    }
    setFileUpload(false);
  };

  const handleFormatChange = (e) => {
    // const selectedFile = allCsvData?.find(
    //   (item) => item?._id === e.target.value
    // );
    // if (selectedFile) {
    //   // setShowMapData(false);
    //   onUpload(selectedFile);
    // }
    setCSVData([]);
    const selectedFile = awsFiles.find((item) => item?._id === e.target.value);
    if (selectedFile) {
      getSingleFileDataFromAWS(
        selectedFile?.fileName,
        selectedFile?.fileType,
        selectedFile?._id
      );
    }
  };

  const handleEmptyFile = () => {
    setFileUpload(false);
    setIsFileEmpty(true);
    setFileName("");
    setCSVData([]);
  };

  const handleFileUpload = (e) => {
    if (selectedFormat?.length === 0) {
      return;
    }
    const file = e.target.files[0];
    console.log(" handleFileUpload file ", file);
    setCreatFileData(file);
    const fileExtention = file?.name?.split(".").pop();
    if (file) {
      setFileUpload(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFileName(file.name);
      setModalShow(false);
      if (fileExtention === "xlsx") {
        handleXlsxUpload(file).then((csvData) => {
          let indexOfStartRow = -1;
          if (fileTypes[2] === selectedFormat) {
            indexOfStartRow = csvData?.indexOf("Date,Time");
          } else if (fileTypes[3] === selectedFormat) {
            indexOfStartRow = csvData?.indexOf("End_Latitude");
          } else if (fileTypes[0] === selectedFormat) {
            indexOfStartRow = csvData?.indexOf("Time PT");
          } else if (fileTypes[1] === selectedFormat) {
            indexOfStartRow = csvData?.indexOf("Time,Latitude,Longitude");
          }
          if (indexOfStartRow !== -1) {
            const filteredData = csvData.substring(indexOfStartRow);
            console.log("filteredData", filteredData);

            Papa.parse(filteredData, {
              header: true,
              skipEmptyLines: true,
              complete: (results) => {
                let parsedData = [];
                // console.log("results?.data", results?.data);
                if (results.data?.length > 0) {
                  if (fileTypes[3] === selectedFormat) {
                    parsedData = results.data.filter(
                      (row) =>
                        row?.["End_Latitude"] !== null &&
                        row?.["End_Latitude"] !== undefined &&
                        row?.["End_Longitude"] !== null &&
                        row?.["End_Longitude"] !== undefined &&
                        row?.["Start_DateTime"] !== null &&
                        row?.["Start_DateTime"] !== undefined
                    );
                    if (parsedData) {
                      parsedData = parsedData?.map((item) => {
                        const {
                          End_Latitude,
                          End_Longitude,
                          Start_DateTime,
                          ...rest
                        } = item;
                        return [
                          {
                            Longitude: End_Longitude,
                            Latitude: End_Latitude,
                            Time: Start_DateTime,
                            isExcelFile: true,
                            ...rest,
                          },
                        ];
                      });
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    }
                  } else if (fileTypes[2] === selectedFormat) {
                    // console.log("inside 2nd=>>>>>>>:::>>>");

                    parsedData = results.data.filter(
                      (row) =>
                        row?.["1st Tower LAT"] !== null &&
                        row?.["1st Tower LAT"] !== undefined &&
                        row?.["1st Tower LONG"] !== null &&
                        row?.["1st Tower LONG"] !== undefined &&
                        row?.["1st Tower LAT"] !== "" &&
                        row?.["1st Tower LONG"] !== ""
                    );

                    if (parsedData) {
                      parsedData = parsedData?.map((item) => {
                        const {
                          ["1st Tower LAT"]: Latitude,
                          ["1st Tower LONG"]: Longitude,
                          Date: date,
                          Time,
                          ...rest
                        } = item;
                        return [
                          {
                            Longitude: Longitude,
                            Latitude: Latitude,
                            // Time: date + " " + Time,
                            Time: date,
                            isExcelFile: true,
                            ...rest,
                          },
                        ];
                      });
                      // parsedData = parsedData.filter(
                      //   (row) =>
                      //     row?.["Latitude"] !== null &&
                      //     row?.["Latitude"] !== undefined &&
                      //     row?.["Longitude"] !== null &&
                      //     row?.["Longitude"] !== undefined
                      //     // row?.["Time"] !== null &&
                      //     // row?.["Time"] !== undefined
                      // );
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    }
                  } else if (selectedFormat === fileTypes[0]) {
                    parsedData = results.data.filter(
                      (row) =>
                        row?.["Time PT"] !== null &&
                        row?.["Time PT"] !== undefined &&
                        row?.["CellLocation"] !== null &&
                        row?.["CellLocation"] !== undefined
                    );
                    if (parsedData?.length > 0) {
                      parsedData = extractCoordinatesAndSector(parsedData);
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    } else {
                      handleEmptyFile();
                    }
                  } else if (selectedFormat === fileTypes[1]) {
                    parsedData = results?.data
                      .filter((row) => {
                        return row?.Time && row?.Latitude && row?.Longitude;
                      })
                      .map((row) => [
                        {
                          Time: row?.Time,
                          Latitude: row?.Latitude,
                          Longitude: row?.Longitude,
                        },
                      ]);
                    if (parsedData?.length > 0) {
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    } else {
                      handleEmptyFile();
                    }
                  } else {
                    handleEmptyFile();
                  }
                } else {
                  handleEmptyFile();
                }
              },
            });
          } else {
            handleEmptyFile();
          }
        });
      } else {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const content = e.target.result;
          let indexOfStartRow = -1;
          if (fileTypes[2] === selectedFormat) {
            indexOfStartRow = content?.indexOf("Date,Time");
          } else if (fileTypes[3] === selectedFormat) {
            indexOfStartRow = content?.indexOf("End_Latitude");
          } else if (fileTypes[0] === selectedFormat) {
            indexOfStartRow = content?.indexOf("Time PT");
          } else if (fileTypes[1] === selectedFormat) {
            indexOfStartRow = content?.indexOf("Time,Latitude,Longitude");
          }
          if (indexOfStartRow !== -1) {
            const filteredData = content.substring(indexOfStartRow);
            Papa.parse(filteredData, {
              skipEmptyLines: true,
              complete: (result) => {
                let parsedData = [];

                if (result?.data?.length > 0) {
                  if (selectedFormat === fileTypes[0]) {
                    parsedData = result.data.filter(
                      (row) =>
                        row?.["Time PT"] !== null &&
                        row?.["Time PT"] !== undefined &&
                        row?.["CellLocation"] !== null &&
                        row?.["CellLocation"] !== undefined
                    );
                    if (parsedData?.length > 0) {
                      parsedData = extractCoordinatesAndSector(parsedData);
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    } else {
                      handleEmptyFile();
                    }
                  } else if (selectedFormat === fileTypes[1]) {
                    parsedData = result?.data
                      .filter((row) => {
                        return row?.Time && row?.Latitude && row?.Longitude;
                      })
                      .map((row) => [
                        {
                          Time: row?.Time,
                          Latitude: row?.Latitude,
                          Longitude: row?.Longitude,
                        },
                      ]);
                    if (parsedData?.length > 0) {
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    } else {
                      handleEmptyFile();
                    }
                  } else if (fileTypes[3] === selectedFormat) {
                    parsedData = result.data.filter(
                      (row) =>
                        row?.["End_Latitude"] !== null &&
                        row?.["End_Latitude"] !== undefined &&
                        row?.["End_Longitude"] !== null &&
                        row?.["End_Longitude"] !== undefined &&
                        row?.["Start_DateTime"] !== null &&
                        row?.["Start_DateTime"] !== undefined
                    );
                    if (parsedData) {
                      parsedData = parsedData?.map((item) => {
                        const {
                          End_Latitude,
                          End_Longitude,
                          Start_DateTime,
                          ...rest
                        } = item;
                        return [
                          {
                            Longitude: End_Longitude,
                            Latitude: End_Latitude,
                            Time: Start_DateTime,
                            ...rest,
                          },
                        ];
                      });
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );

                      // onUpload(parsedData);
                    }
                  } else if (fileTypes[2] === selectedFormat) {
                    // console.log("inside 2nd=>>>>>>>");

                    parsedData = result.data.filter(
                      (row) =>
                        row?.["1st Tower LAT"] !== null &&
                        row?.["1st Tower LAT"] !== undefined &&
                        row?.["1st Tower LONG"] !== null &&
                        row?.["1st Tower LONG"] !== undefined &&
                        row?.["1st Tower LAT"] !== "" &&
                        row?.["1st Tower LONG"] !== ""
                    );

                    if (parsedData) {
                      parsedData = parsedData?.map((item) => {
                        const {
                          ["1st Tower LAT"]: Latitude,
                          ["1st Tower LONG"]: Longitude,
                          Date: date,
                          Time,
                          ...rest
                        } = item;
                        return [
                          {
                            Longitude: Longitude,
                            Latitude: Latitude,
                            Time: date + " " + Time,
                            ...rest,
                          },
                        ];
                      });
                      addFileApiHandler(
                        parsedData,
                        file.name,
                        selectedFormat,
                        file
                      );
                      // onUpload(parsedData);
                    }
                  } else {
                    handleEmptyFile();
                  }
                } else {
                  handleEmptyFile();
                }
              },
              header: true,
              dynamicTyping: true,
              dateFormats: ["M/D/YYYY h:m A", "M/D/YYYY H:m"],
            });
          } else {
            handleEmptyFile();
          }
        };

        fileReader.readAsText(file);
      }
    }
    // };
  };

  return (
    <>
      {modalShow && (
        <CustomModal
          onHide={() => setModalShow(false)}
          setSelectedFormat={setSelectedFormat}
          selectedFormat={selectedFormat}
          fileInputRef={fileInputRef}
          handleFileUpload={handleFileUpload}
        />
      )}
      {fileUpload && <CustomLoader />}
      <div className="relative w-[320px]">
        <h1 className="text-center text-[#443A39] font-bold text-lg lg:text-4xl mb-5">
          CSV Slider Map
        </h1>

        <div
          className={`relative time-section border-[1px] cursor-pointer`}
          onClick={() => {
            setModalShow(true);
          }}
        >
          <div className="text-white text-ellipsis line-clamp-1 overflow-hidden">
            <span>{csvData?.fileName || "Add Evidence"}</span>
          </div>
        </div>
        {!isApiCalled && (
          <div className="w-full flex justify-center items-center  mt-5">
            <div className="animate-spin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 4335 4335"
                width="54.42"
                height="54.42"
              >
                <path
                  fill="#8B5CF6"
                  d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                />
              </svg>
            </div>
          </div>
        )}
        {awsFiles?.length === 0 && isApiCalled && (
          <div className="text-center text-[#443A39] font-bold text-lg lg:text-2xl mt-8">
            No Recent Files
          </div>
        )}
        {/* {isApiCalled && allCsvData?.length > 0 && (
          <div className="custom-select mt-5 ">
            <select
              name="csvFiles"
              id="csvFiles"
              value={csvData?._id || ""}
              onChange={handleFormatChange}
              className=" rounded-3xl w-full appearance-none"
            >
              <option value="" className="subpixel-antialiased" disabled selected>
                Select a file
              </option>
              
              {allCsvData?.map((item) => {
                return (
                  <option value={item?._id} key={item?._id} className="">
                    {item?.fileName}
                  </option>
                );
              })}
             
            </select>
          </div>
        )} */}
        {isApiCalled && awsFiles?.length > 0 && (
          <div className="custom-select mt-5 ">
            <select
              name="csvFiles"
              id="csvFiles"
              value={csvData?._id || ""}
              onChange={handleFormatChange}
              className=" rounded-xl w-full appearance-none"
            >
              <option
                value=""
                className="subpixel-antialiased"
                disabled
                selected
              >
                Select a Recent File
              </option>

              {awsFiles?.map((item) => {
                return (
                  <option value={item?._id} key={item?._id}>
                    {item?.fileName}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
    </>
  );
};

export default CSVUpload;
