import React, { useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/layout/authLayout";
import {
  EmailIcon,
  LockIcon,
  EyeOpenIcon,
  EyeCloseIcon,
} from "../../assets/icons";
import { LoginValidationScheme } from "../../utils/LoginValidation";
import "../../styles/login.css";
import { login } from "../../api/auth";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inValidLogin, setInValidLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    try {
      const response = await login(values);
      console.log("response: ", response);
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("email", response.email);
      navigate("/map");
    } catch (error) {
      console.log("login error: ", error);
      setErrorMessage(error.response?.data?.message || "Login failed");
      setInValidLogin(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout isSignUp={false}>
      <div className="h-full w-full bg-[#F5F5F5] flex justify-center items-center px-3 sm:px-0">
        <div className="bg-white rounded-lg shadow-lg w-full sm:w-[60%] lg:w-[540px] p-8 flex flex-col gap-6">
          {inValidLogin && (
          <div className="text-white text-base font-bold text-center px-4 py-2 bg-red-400 border border-red-700 rounded-lg max-w-[250px] self-center">{errorMessage}</div>
        )}
          <div>
            <div className="text-[#0C5CDC] text-2xl font-bold ">Welcome</div>
            <div className="text-[#213660]">Sign in and get started.</div>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={onSubmitHandler}
            validationSchema={LoginValidationScheme}
          >
            {({ handleChange, handleSubmit, touched, errors }) => (
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                  <div className="relative border border-[#E7E8EC] w-full py-3 px-4 gap-3 rounded-lg flex">
                    <img src={EmailIcon} alt="email-icon" />
                    <div className="flex flex-col">
                      <label
                        className="text-[#6E6D7A] text-md"
                        htmlFor={"email"}
                      >
                        {"Email"}
                      </label>

                      <input
                        type="text"
                        id={"email"}
                        placeholder={"Somebody@email.com"}
                        onChange={handleChange("email")}
                        className="focus:outline-none  text-[#6E6D7A]"
                      />
                    </div>
                  </div>
                  {touched.email && errors.email && (
                    <div className="text-red-700 text-sm -mt-3">
                      {errors.email}
                    </div>
                  )}

                  <div className="relative border border-[#E7E8EC] w-full py-3 px-4 gap-3 rounded-lg flex justify-between items-center">
                    <div className="flex gap-3">
                      <img src={LockIcon} alt="password icon" />
                      <div className="flex flex-col">
                        <label
                          className="text-[#6E6D7A] text-md "
                          htmlFor={"password"}
                        >
                          {"Password"}
                        </label>

                        <input
                          type={showPassword ? "text" : "password"}
                          id={"password"}
                          placeholder={"********"}
                          onChange={handleChange("password")}
                          className="focus:outline-none text-[#6E6D7A]"
                        />
                      </div>
                    </div>
                    <>
                      {!showPassword ? (
                        <span
                          className="hover:cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img
                            src={EyeOpenIcon}
                            alt="eye-open-icon"
                            height={20}
                            width={20}
                          />
                        </span>
                      ) : (
                        <span
                          className="hover:cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img
                            src={EyeCloseIcon}
                            alt="eye-close-icon"
                            height={20}
                            width={20}
                          />
                        </span>
                      )}
                    </>
                  </div>
                  {touched.password && errors.password && (
                    <div className="text-red-700 text-sm -mt-3">
                      {errors.password}
                    </div>
                  )}
                  <div
                    className="text-[#0C5CDC] font-bold hover:cursor-pointer"
                    onClick={() => navigate("/forget-password")}
                  >
                    Forget Password?
                  </div>
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="w-full relative border rounded-full py-3 font-bold text-xl text-[#213660] border-[#BAE3F6] sign_in_button overflow-hidden"
                  disabled={isLoading}
                >
                  <div className="w-full flex justify-center">
                    <div className="absolute top-0 glass_shade" />
                  </div>
                  <div className="relative">
                    {isLoading ? (
                      <div className="w-full flex justify-center items-center">
                        <div className="animate-spin">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4335 4335"
                            width="26"
                            height="26"
                          >
                            <path
                              fill="#8B5CF6"
                              d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      "Sign In"
                    )}
                  </div>
                </button>
              </div>
            )}
          </Formik>
          <div className="px-[10%]">
            <hr className="bg-gray-700 border-1 rounded" />
          </div>
          <button
            type="button"
            className="relative w-full border rounded-full py-3 text-xl font-GillSansBold text-[#213660] learn_more_button overflow-hidden"
            onClick={() => navigate("/learn-more")}
          >
            <div className="w-full flex justify-center">
              <div className="absolute top-0 glass_shade" />
            </div>
            <div className="relative">Learn More</div>
          </button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
