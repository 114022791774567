import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "../components/layout/dashboardLayout";

const PrivateRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  return <DashboardLayout>{children}</DashboardLayout>;
};

export default PrivateRoute;
