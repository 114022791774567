import React, { useState } from "react";
import "../../styles/customModal.css";
import LogoSvg from "../../assets/icons/logo";
import ChevronIcon from "../../assets/icons/chevronIcon";
import { fileTypes } from "../../constants/FileTypes";

const CustomModal = ({
  onHide,
  setSelectedFormat,
  selectedFormat,
  fileInputRef,
  handleFileUpload,
}) => {
  const [error, setError] = useState("");

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
      {/* Light background overlay */}
      <div className="fixed inset-0 bg-gray-600 opacity-50" />

      {/* Modal content */}
      <div
        className={`bg-white mx-5 sm:mx-auto w-full max-w-[600px] rounded-lg shadow-2xl z-50 opacity-100 px-4 sm:px-8 lg:px-12 relative pt-6 pb-4 sm:pb-8 flex flex-col`}
      >
        <div className="bg-white rounded-lg flex items-center flex-col gap-4">
          <LogoSvg className="h-11 w-52" />
          <p className="text-[#443A39] text-base">
            Please Select a file format
          </p>
          <div className="border border-gray-300 rounded-md w-full">
            <p className="bg-[#E6EFFE] text-center font-bold text-base lg:text-lg py-5">
              Select Evidence Type
            </p>
            <div className=" overflow-y-auto scrollBar_style mt-1">
              {fileTypes.map((type, index) => (
                <div
                  key={index}
                  className={`px-5 group flex justify-between items-center py-4 cursor-pointer hover:bg-[#F2F4F8] hover:text-gray-800 active:text-gray-800 border_bottom text-gray-500 hover:font-medium text-base ${
                    selectedFormat === type && "bg-[#F2F4F8] text-gray-800"
                  }`}
                  onClick={() => setSelectedFormat(type)}
                >
                  {type}
                  <ChevronIcon />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="flex w-full mt-5 max-w-[300px] gap-5">
            <button
              className={`flex-1 relative w-full cance_button_bg cursor-pointer py-2 rounded-3xl`}
              type="button"
              onClick={onHide}
            >
              <div className="absolute top-0 cancel_glass_shade" />
              Cancel
            </button>
            <div
              className={`relative flex-1 w-full continue_bg  text-white cursor-pointer py-2 rounded-3xl`}
              type="button"
            >
              <div className="absolute top-0 continue_glass_shade" />
              <input
                ref={fileInputRef}
                type="file"
                accept="*"
                onChange={(e) => {
                  handleFileUpload(e);
                }}
                className="absolute inset-0 w-full h-12 opacity-0 cursor-pointer "
                onClick={(event) => {
                  if (selectedFormat?.length === 0) {
                    setError("Please Select Evidence Type");
                    event.preventDefault();
                  }
                }}
              />
              Continue
            </div>
          </div>
        </div>
        {error && (
          <div className="text-xs text-red-500 w-full text-end mt-3">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
