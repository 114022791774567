import React from "react";
import { CloseIcon, WarningIcon } from "../../assets/icons";

const Alert = ({ closeIconAction }) => {
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
      {/* Light background overlay */}
      <div className="fixed inset-0 bg-gray-600 opacity-50" />

      {/* Modal content */}
      <div
        className={`bg-white mx-auto w-96 rounded-lg shadow-2xl z-50 opacity-100 p-4 relative`}
      >
        {
          <div
            className="cursor-pointer flex justify-end"
            onClick={() => closeIconAction()}
          >
            <img src={CloseIcon} alt="close-icon" className="text-black w-6 h-6" />
          </div>
        }
        <div className="bg-white rounded-lg flex items-center flex-col gap-2">
          <div>
            <img
              src={WarningIcon}
              alt="warning-icon"
              className="text-black w-14 h-14"
            />
          </div>
          <div className="border border-gray-300 rounded-md p-4">
            The file is empty or the file format is incorrect. Please select a
            correct file to proceed.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
