import Papa from "papaparse";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { fileTypes } from "../../constants/FileTypes";

const extractData = (data, time) => {
  const splitData = data?.split(":");
  if (splitData[0]?.includes("/")) {
    const [, Longitude, Latitude, Sector, Beamwidth] = splitData;
    return {
      Longitude: parseFloat(Longitude),
      Latitude: parseFloat(Latitude),
      Sector: parseFloat(Sector),
      Time: time,
      Beamwidth: Beamwidth,
    };
  } else {
    const [, , Longitude, Latitude, Sector, Beamwidth] = splitData;
    return {
      Longitude: parseFloat(Longitude),
      Latitude: parseFloat(Latitude),
      Sector: parseFloat(Sector),
      Time: time,
      Beamwidth: Beamwidth,
    };
  }
};

const extractCoordinatesAndSector = (fileData) => {
  const extractedDataArray = fileData?.map((data) => {
    const trimmedData = data["CellLocation"]?.slice(1, -1);
    if (trimmedData?.includes(";")) {
      const splitData = trimmedData?.split(";");
      return splitData?.map((items) => extractData(items, data["Time PT"]));
    } else {
      return [extractData(trimmedData, data["Time PT"])];
    }
  });
  return extractedDataArray;
};

// const handleXlsxUpload = async (fileData) => {
//   try {
//     const workbook = XLSX.read(fileData, { type: "buffer" });

//     const sheetName = workbook.SheetNames[0];
//     const worksheet = workbook.Sheets[sheetName];

//     const csvData = XLSX.utils.sheet_to_csv(worksheet);
//     return csvData;
//   } catch (error) {
//     console.error("Error processing XLSX:", error);
//   }
// };

const handleXlsxUpload = async (fileData) => {
  try {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(fileData); // Load the workbook from the buffer

    const worksheet = workbook.worksheets[0]; // Get the first worksheet

    // Convert the worksheet to CSV manually
    let csvData = "";
    worksheet.eachRow((row) => {
      const rowData = row.values
        .slice(1) // Remove the first element which is the row number
        .map((cell) => (cell !== undefined ? cell : ""))
        .join(",");
      csvData += `${rowData}\n`;
    });
    // console.log('csvData', csvData)
    return csvData;
  } catch (error) {
    console.error("Error processing XLSX:", error);
  }
};

export const handleFileUpload = async (file, selectedFormat, fileName) => {
  if (!selectedFormat || selectedFormat.length === 0) {
    return;
  }

  const fileExtention = fileName.split(".").pop().toLowerCase();
  if (file) {
    if (fileExtention === "xlsx") {
      const csvData = await handleXlsxUpload(file);
      return processCsvData(csvData, selectedFormat, fileName, true);
    } else {
      const data = processCsvData(file, selectedFormat, fileName, false);
      return data;
    }
  }
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
    fileReader.readAsText(file);
  });
};

const processCsvData = (csvData, selectedFormat, fileName, isExcel) => {
  let indexOfStartRow = -1;
  let parsedData = [];
  if (fileTypes[2] === selectedFormat) {
    indexOfStartRow = csvData?.indexOf("Date,Time");
  } else if (fileTypes[3] === selectedFormat) {
    indexOfStartRow = csvData?.indexOf("End_Latitude");
  } else if (fileTypes[0] === selectedFormat) {
    indexOfStartRow = csvData?.indexOf("Time PT");
  } else if (fileTypes[1] === selectedFormat) {
    indexOfStartRow = csvData?.indexOf("Time,Latitude,Longitude");
  }

  if (indexOfStartRow !== -1) {
    const filteredData = csvData.substring(indexOfStartRow);
    Papa.parse(filteredData, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        // let parsedData = [];
        if (results.data?.length > 0) {
          if (fileTypes[3] === selectedFormat) {
            parsedData = filterAndTransformData({
              data: results.data,
              latKey: "End_Latitude",
              longKey: "End_Longitude",
              dateKey: "Start_DateTime",
              timeKey: undefined,
              isExcel: isExcel,
            });
          } else if (fileTypes[2] === selectedFormat) {
            parsedData = filterAndTransformData({
              data: results.data,
              latKey: "1st Tower LAT",
              longKey: "1st Tower LONG",
              dateKey: "Date",
              timeKey: isExcel ? undefined : "Time",
              isExcel: isExcel,
            });
          } else if (selectedFormat === fileTypes[0]) {
            parsedData = results.data.filter(
              (row) =>
                row?.["Time PT"] !== null &&
                row?.["Time PT"] !== undefined &&
                row?.["Time PT"] !== "" &&
                row?.["CellLocation"] !== null &&
                row?.["CellLocation"] !== undefined &&
                row?.["CellLocation"] !== ""
            );
            if (parsedData?.length > 0) {
              console.log("parsedData: ", parsedData);
              parsedData = extractCoordinatesAndSector(parsedData);
            }
          } else if (selectedFormat === fileTypes[1]) {
            parsedData = results.data
              .filter((row) => row?.Time && row?.Latitude && row?.Longitude)
              .map((row) => [
                {
                  Time: row?.Time,
                  Latitude: row?.Latitude,
                  Longitude: row?.Longitude,
                },
              ]);
          }

          if (parsedData?.length > 0) {
            const payload = {
              data: parsedData,
              fileType: selectedFormat,
              fileName: fileName,
            };
            // handleCSVUpload(payload)
            // return parsedData;
          } else {
            // handleEmptyFile();
          }
        } else {
          // handleEmptyFile();
        }
      },
    });
  } else {
    // handleEmptyFile();
  }
  return parsedData;
};

const filterAndTransformData = ({
  data,
  latKey,
  longKey,
  dateKey,
  timeKey = null,
  isExcel = false,
}) => {
  let parsedData = data.filter(
    (row) =>
      row?.[latKey] !== null &&
      row?.[latKey] !== undefined &&
      row?.[latKey] !== "" &&
      row?.[longKey] !== null &&
      row?.[longKey] !== undefined &&
      row?.[longKey] !== "" &&
      row?.[dateKey] !== "" &&
      row?.[dateKey] !== null &&
      row?.[dateKey] !== undefined
  );

  if (timeKey) {
    parsedData = parsedData.filter(
      (row) =>
        row?.[timeKey] !== null &&
        row?.[timeKey] !== undefined &&
        row?.[timeKey] !== ""
    );
  }

  if (parsedData) {
    parsedData = parsedData.map((item) => {
      const latitude = item[latKey];
      const longitude = item[longKey];
      const dateTime = timeKey
        ? item[dateKey] + " " + item[timeKey]
        : item[dateKey];

      return [
        {
          ...item,
          Longitude: longitude,
          Latitude: latitude,
          Time: dateTime,
          isExcelFile: isExcel,
        },
      ];
    });
    return parsedData;
  }

  return parsedData;
};
