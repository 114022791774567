import React from "react";
import AuthLayout from "../../components/layout/authLayout";

const ForgetPassword = () => {
  return (
    <AuthLayout isSignUp={true}>
      <div className="h-full w-screen flex justify-center items-center text-2xl text-[#213660]">
        Please contact support
        <a
          href="mailto:support@trialtrackpro.com"
          className="ml-1 text-[#0C5CDC] underline underline-offset-2"
        >
          {"support@trialtrackpro.com"}
        </a>
      </div>
    </AuthLayout>
  );
};

export default ForgetPassword;
