/* eslint-disable react-hooks/exhaustive-deps */
// App.js
import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import CSVUpload from "../../components/common/CSVUpload";
import MapComponent from "../../components/common/Map";
import Alert from "../../components/common/alert";
import "../../App.css";
import { getAllFiles } from "../../api/file";
import ConvertDate from "../../components/common/DateTime";
import AWS from "aws-sdk";

const initialData = {
  previousLocation: [
    {
      Latitude: 0,
      Longitude: 0,
      Time: "00:00",
      Sector: 0,
      Beamwidth: "",
    },
  ],
  currentLocation: [
    {
      Latitude: 0,
      Longitude: 0,
      Time: "00:00",
      Sector: 0,
      Beamwidth: "",
    },
  ],
};

export const getFormatedDateTime = (time) => {
  if (time?.length > 0) {
    const timeMatches = time.match(/(\d{1,2}):(\d{2})(:(\d{2}))?\s*(AM|PM)?/);
    if (timeMatches) {
      let hours = timeMatches[1];
      let minutes = timeMatches[2];
      let amPm = timeMatches[5] || "AM";
      if (hours === "00") {
        hours = "12";
      } else if (hours > 12) {
        hours = (hours - 12).toString();
        amPm = "PM";
      }
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      return `${
        time?.split(" ")?.length > 0 ? time?.split(" ")[0] : ""
      } ${hours}:${minutes} ${amPm}`;
    } else {
      return "00:00";
    }
  }
};

const S3_BUCKET = "aws-upload-test-1";
const REGION = "ap-south-1";
AWS.config.update({
  accessKeyId: "AKIA2UC3B3PWSFFWYVG5",
  secretAccessKey: "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",
});

const Map = () => {
  const [csvData, setCSVData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isFileEmpty, setIsFileEmpty] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [locations, setLocations] = useState(initialData);
  const [showMapData, setShowMapData] = useState(false);
  const [allCsvData, setAllCsvData] = useState([]);
  const [isApiCalled, setIsApiCalled] = useState(false);

  const [awsFiles, setAwsFiles] = useState([]);

  const fetchObjects = async () => {
    const email = localStorage.getItem("email");

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Prefix: `${email}/`,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      let keyName = data?.Contents.map((item) => item?.Key.split("/")?.[1]);
      const response = await getAllFiles();
      if (keyName && response) {
        const formatedData = keyName
          ?.map((fileName) => {
            const matchedItem = response.find(
              (obj) => obj.fileName === fileName
            );
            return matchedItem ? { fileName, ...matchedItem } : null;
          })
          ?.filter((item) => item !== null);
        setAwsFiles(formatedData);
      }
    } catch (err) {
      console.error("Error fetching objects:", err);
      alert("Error fetching objects. Check the console for more details.");
    }
    setIsApiCalled(true);
  };

  useEffect(() => {
    fetchObjects();
  }, []);

  const handleCSVUpload = (data, isNewFile) => {
    setCurrentTime(0);
    if (data?.data?.length > 0) {
      setLocations({
        previousLocation: initialData?.previousLocation,
        currentLocation: Array.isArray(data?.data[0][0])
          ? data?.data[0][0]
          : [data?.data[0][0]],
      });
      setSelectedFormat(data?.fileType);
      setCSVData(data);
      if (isNewFile) {
        // setAllCsvData([...allCsvData, data]);
        fetchObjects();
      }
    }
  };

  useEffect(() => {
    if (isFileEmpty) {
      setLocations(initialData);
      setCurrentTime(0);
      setCSVData([]);
      setSelectedFormat("");
    }
  }, [isFileEmpty]);

  useEffect(() => {
    if (showMapData) {
      setLocations(initialData);
      setCurrentTime(0);
      setCSVData([]);
    }
  }, [showMapData]);

  const unloadButtonHandler = () => {
    setShowMapData(true);
    setSelectedFormat("");
    setLocations(initialData);
    setCurrentTime(0);
    setCSVData([]);
  };
  return (
    <div className="relative flex flex-col justify-center items-center">
      {isFileEmpty && <Alert closeIconAction={() => setIsFileEmpty(false)} />}
      <MapComponent locations={locations} />
      <div className="box-shadow absolute ease-in-out duration-150 z-10 left-4 bottom-3 sm:top-3 h-fit rounded-xl bg-[#F2EAE8] flex flex-col justify-center items-center gap-5 py-5 px-3 sm:px-6 lg:min-w-[410px]">
        <div className="flex flex-row gap-4 w-full">
          <button
            className={`flex-1 w-full ${
              selectedFormat === ""
                ? "bg-[#D0D0D0] load-button cursor-not-allowed"
                : "bg-[#4A90E2] button-unload text-white cursor-pointer"
            } py-2 rounded-3xl transition-all duration-250 hover:transform hover:scale-105 `}
            type="button"
            onClick={() => unloadButtonHandler()}
            disabled={selectedFormat === "" ? true : false}
          >
            Map Unload
          </button>
        </div>
        <CSVUpload
          onUpload={handleCSVUpload}
          setSelectedFormat={setSelectedFormat}
          selectedFormat={selectedFormat}
          setIsFileEmpty={setIsFileEmpty}
          showMapData={showMapData}
          setShowMapData={setShowMapData}
          isApiCalled={isApiCalled}
          setIsApiCalled={setIsApiCalled}
          allCsvData={allCsvData}
          csvData={csvData}
          awsFiles={awsFiles}
          setCSVData={setCSVData}
        />
        <input
          type="range"
          min={0}
          max={csvData?.data?.length - 1}
          value={currentTime}
          // onChange={(e) => {
          //   let parsedValue = parseInt(e.target.value);
          //   setCurrentTime(parsedValue);
          //   if (parsedValue > 0) {
          //     const updatedLocation = {
          //       previousLocation: Array.isArray(csvData[parsedValue - 1])
          //         ? csvData[parsedValue - 1]
          //         : [csvData[parsedValue - 1]],
          //       currentLocation: Array.isArray(csvData[parsedValue])
          //         ? csvData[parsedValue]
          //         : [csvData[parsedValue]],
          //     };
          //     setLocations(updatedLocation);
          //   } else {
          //     setLocations({
          //       currentLocation: Array.isArray(csvData[0])
          //         ? csvData[0]
          //         : [csvData[0]],
          //       previousLocation: initialData?.previousLocation,
          //     });
          //   }
          // }}
          onChange={(e) => {
            // console.log("onChange", csvData);
            if (csvData?.data?.length > 0) {
              let parsedValue = parseInt(e.target.value);
              setCurrentTime(parsedValue);
              // console.log('parsedValue', parsedValue)
              if (parsedValue > 0) {
                const previousLocationData = csvData?.data[parsedValue - 1];
                const currentLocationData = csvData?.data[parsedValue];
                const updatedLocation = {
                  previousLocation: Array.isArray(previousLocationData)
                    ? previousLocationData
                    : [previousLocationData],
                  currentLocation: Array.isArray(currentLocationData)
                    ? currentLocationData
                    : [currentLocationData],
                };
                let validData = true;
                updatedLocation.previousLocation.forEach((loc) => {
                  loc.Latitude = parseFloat(loc.Latitude) || 0;
                  loc.Longitude = parseFloat(loc.Longitude) || 0;
                  if (loc.Latitude === 0 || loc.Longitude === 0) {
                    validData = false;
                  }
                });

                updatedLocation.currentLocation.forEach((loc) => {
                  loc.Latitude = parseFloat(loc.Latitude) || 0;
                  loc.Longitude = parseFloat(loc.Longitude) || 0;
                  if (loc.Latitude === 0 || loc.Longitude === 0) {
                    validData = false;
                  }
                });
                // const updatedLocation = {
                //   previousLocation: Array.isArray(
                //     csvData?.data[parsedValue - 1]
                //   )
                //     ? csvData?.data[parsedValue - 1]
                //     : [csvData?.data[parsedValue - 1]],
                //   currentLocation: Array.isArray(csvData?.data[parsedValue])
                //     ? csvData?.data[parsedValue]
                //     : [csvData?.data[parsedValue]],
                // };
                if (validData) setLocations(updatedLocation);
              } else {
                setLocations({
                  currentLocation: Array.isArray(csvData?.data[0])
                    ? csvData?.data[0]
                    : [csvData?.data[0]],
                  previousLocation: initialData?.previousLocation,
                });
              }
            }
          }}
          className="h-5 w-full rounded-lg outline-4 "
        />
        {locations?.currentLocation?.[0]?.Latitude !== 0 ? (
          <ConvertDate
            fileDateTime={csvData?.data?.[currentTime]?.[0]?.Time}
            fileLatitude={locations?.currentLocation?.[0]?.Latitude}
            fileLongitude={locations?.currentLocation?.[0]?.Longitude}
            isExcelFile={
              locations?.currentLocation?.[0]?.isExcelFile
                ? locations?.currentLocation?.[0]?.isExcelFile
                : false
            }
          />
        ) : (
          <p className="text-xs time-style text-center w-full lg:text-base leading-normal ">
            Current Time: <span className="font-bold ">00:00</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Map;
