/* eslint-disable react-hooks/exhaustive-deps */
// App.js
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Map from "./pages/map";
import Login from "./pages/login";
import SignUp from "./pages/signup";
import LearnMore from "./pages/learnMore";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";
import ForgetPassword from "./pages/forgetPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

const routes = [
  {
    path: "/",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  // {
  //   path: "/signup",
  //   element: (
  //     <PublicRoute>
  //       <SignUp />
  //     </PublicRoute>
  //   ),
  // },
  {
    path: "/map",
    element: (
      <PrivateRoute>
        <Map />
      </PrivateRoute>
    ),
  },
  {
    path: "/learn-more",
    element: (
      <PublicRoute>
        <LearnMore />
      </PublicRoute>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <PublicRoute>
        <ForgetPassword />
      </PublicRoute>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export default App;
