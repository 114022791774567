import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/header.css";
import LogoSvg from "../../../assets/icons/logo";
// import LogoColourSvg from "../../../assets/icons/logoColour";
// import Logo from "../../../assets/icons/Trial Track Logo.png"

const AuthLayout = ({ children, isSignUp }) => {
  const navigate = useNavigate();
    console.log("navigate.name", navigate.name);

  return (
    <div className="h-full">
      <div className="w-full flex justify-between items-center bg-[#D6E3EC] px-6 py-5 sticky top-0 z-10">
        <div onClick={() => (isSignUp ? navigate("/") : navigate("/map"))} className="cursor-pointer" >
        <LogoSvg className="h-11 w-52"/>
        </div>
        {/* <div className="flex justify-center items-center gap-5">
          <button
            className="relative border rounded-full py-3  text-sm  font-bold  text-[#213660] border-[#BAE3F6] overflow-hidden create_account_bg w-[120px]"
            // onClick={() => (isSignUp ? navigate("/") : navigate("/signup"))}
            onClick={() => navigate("/")}
          >
            <div className="w-full flex justify-center">
              <div className="absolute top-0 glass_shade_button" />
            </div>
            <div className="relative">
              {isSignUp ? "Sign In" : "Create Account"}
            </div>
          </button>
        </div> */}
      </div>
      <div
        style={{
          height: "calc(100vh - 84px)",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
