import { axiosPost, axiosGet } from "../../utils/axios";

export const addFile = async (payload) => {
  const res = await axiosPost({ path: "file/add-file", payload: payload });
  return res;
};

export const getAllFiles = async () => {
  const res = await axiosGet({ path: "file/list" });
  return res;
};
