import { axiosPost } from "../../utils/axios";

export const register = async (payload) => {
  const res = await axiosPost({ path: "auth/register", payload: payload });
  return res;
};

export const login = async (payload) => {
  const res = await axiosPost({ path: "auth/signin", payload: payload });
  return res;
};
