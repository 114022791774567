const LocationMarker = (props) => {
    return (
      <svg
        width="69"
        height="114"
        viewBox="0 0 69 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M65.6965 19.6356C67.995 24.3538 69 29.3585 69 34.3621C69 39.5093 67.8507 44.514 65.6965 49.0887L34.5259 114L3.35532 49.0887C-4.83285 31.9318 2.49362 11.4859 19.5873 3.33624C36.8242 -4.81291 57.5091 2.47853 65.6967 19.6356H65.6965ZM46.0176 34.3621C46.0176 28.0709 40.8465 22.9238 34.5259 22.9238C28.2053 22.9238 23.0342 28.0709 23.0342 34.3621C23.0342 40.6533 28.2053 45.8005 34.5259 45.8005C40.8465 45.8005 46.0176 40.6533 46.0176 34.3621V34.3621Z"
          fill={props.color || '#000000'}
        />
        <circle cx="35" cy="35" r="12" fill="white"></circle>
      </svg>
    )
  }
  
  export default LocationMarker
  